/**
 * Google Tag manager
 */
export namespace GTM {
  /**
   * container id
   */
  export const CONTAINER_ID = 'GTM-5Z2Z98G'

  /**
   * action区分
   */
  export namespace ACTION {
    export const PAGE_VIEW = 'page_view'
    export const CLICK     = 'click'
    export const INPUT     = 'input'
    export const CHANGE    = 'change'
  }

  /**
   * TOPページ
   */
  export namespace TOP {
    export const CATEGORY = "TOP"
    export namespace LABEL {
      export const CLICK_CURRENT_LOCATION = "click_current_location"  //- 「現在地から探す」をクリック
      export const CLICK_AREA             = "click_region"            //- 「地域から探す」をクリック
      export const CLICK_LINE             = "click_line"              //- 「路線から探す」をクリック
    }
  }

  /**
   * SPA汎用イベント
   */
  export namespace SPA {
    export const CATEGORY = "SPA"
    export namespace LABEL {
      export const NAVIGATION_START  = 'navigation_start'
      export const NAVIGATION_END    = 'navigation_end'
      export const NAVIGATION_CANCEL = 'navigation_cancel'
      export const NAVIGATION_ERROR  = 'navigation_error'
    }
  }

  /**
   * 園見学予約
   */
  export namespace VISIT_RESERVATION {
    export const CATEGORY = "園見学予約フォーム"
    export namespace LABEL {
      export const CLICK_CONFIRM_BUTTON     = "click_confirm_button"      //- 確認画面ボタンをクリック
      export const CLICK_BACK_BUTTON        = "click_back_button"         //- 戻るボタンをクリック
      export const CLICK_APPLY_BUTTON       = "click_apply_button"        //- 申し込みボタンをクリック
      export const CLICK_DETAIL_BUTTON      = "click_detail_button"       //- 詳細画面に戻るボタンをクリック
      export const CLICKABLE_CONFIRM_BUTTON = "clickable_confirm_button"  //- 詳細画面に戻るボタンがクリッカブル
      export const CLICKABLE_APPLY_BUTTON   = "clickable_apply_button"    //- 申し込みボタンがクリッカブル
      export const CHANGE_FORM_STEP         = "change_form_step"          //- フォームのステップ遷移
    }
  }
  /**
   * コンテンツ
   */
  export namespace CONTENTS {
    export const CATEGORY = "コンテンツ"
    export namespace LABEL {
      export const CLICK_VISIT_RESERVATION  = "click_visit_reservation"   //- 園見学に進むボタンをクリック
      export const CLICK_SHOW_ALL_REVIEW    = "click_show_all_review"     //- 全ての口コミを見るボタンをクリック
      export const CLICK_HOIKU_LIB_BANNER   = "click_hoiku_lib_banner"    //- 保育士求人ライブラリバナーをクリック
      export const CLICK_HOIKU_LIB_BRANCH   = "click_hoiku_lib_branch"    //- 保育士求人ライブラリ:「園の求人を見る」をクリック
      export const CLICK_FACILITY_RECOMMEND = "click_facility_recommend"  //- 保育園レコメンドをクリック
      export const CLICK_STYLE_BANNER       = "click_style_banner"        //- KIDSNA STYLEバナーをクリック
      export const CLICK_PRECAM_BANNER      = "click_precam_banner"       //- プレキャンバナーをクリック
      export const CLICK_SITTER_BANNER      = "click_sitter_banner"       //- KIDSNAシッターバナーをクリック
    }
  }

  /**
   * 広告
   */
  export namespace AD {
    export const CATEGORY = "広告"
    export namespace LABEL {
      export const CLICK_AREA_SPONSORED_BANNER  = "click_area_sponsored_banner"   //- エリアスポンサード広告:バナーをクリック
      export const CLICK_AREA_SPONSORED_ARTICLE = "click_area_sponsored_article"  //- エリアスポンサード広告:記事内リンク「詳しくはこちら」をクリック
    }
  }
}

export interface CustomEventParams {
  category?: string;
  label?:    string;
  action?:   string;
  value?:    string | object;
}

/**
 * Google Tag manager: DataLayer
 */
export namespace DataLayer {
  /**
   * カスタムイベントをpushする
   */
  export const pushEvent = (params: CustomEventParams, callback?: () => void ) => {
    //- パラメータをセット
    const data: any = {
      event: "analytics-gtm-event-custom",
    }
    if(params.category) data.eventCategory = params.category
    if(params.label)    data.eventLabel    = params.label
    if(params.action)   data.eventAction   = params.action
    if(params.value)    data.eventValue    = params.value
    if(callback)        data.eventCallback = (containerId: string) => {
      if (containerId == GTM.CONTAINER_ID) callback()  // ContainerIdが一致する時だけcallbackを実行（複数回発火されるのを防止する）
    }
    // @ts-ignore
    window.dataLayer.push(data)
  }
}

// @ts-ignore
globalThis.CustomGTM = { GTM, DataLayer };
